import React from 'react';
import { InputColumn } from '../../../../../components/forms/InputColumn';
import { ServiceError } from '../../../../../types/Service';
import { DatePickerColumn } from '../../../../../components/forms/DatePickerColumn';
import { TaxBandsInfo } from '../../../../../types/TaxBands';
import { useTranslation } from 'react-i18next';
import { TaxBandsUpdateFormFieldValue } from './TaxBandsForm';

interface IProps {
  error?: ServiceError;
  onChange: (value: TaxBandsUpdateFormFieldValue) => void;
  taxBands: TaxBandsInfo;
  addTaxBands: (taxBandsInfo: TaxBandsInfo) => void;
  showAllFields: boolean;
}

export const TaxBandsFormRow: React.FC<IProps> = ({
  error,
  onChange,
  taxBands,
  addTaxBands,
  showAllFields
}) => {
  const { t } = useTranslation();
  return (
    <div className="form-group row">
      <div className="col-md-12">
        <div className="form-row">
          <InputColumn
            label={t('taxBands.table.label')}
            onChange={(e) => onChange({ name: e.target.name, value: e.target.value })}
            name={'label'}
            value={taxBands.label}
            placeholder={t('taxBands.table.label')}
            error={error}
            numberOfColumnsInRow={3}
          />
          <InputColumn
            label={t('taxBands.table.initialQuantity')}
            onChange={(e) => onChange({ name: e.target.name, value: e.target.value })}
            name={'initialQuantity'}
            value={taxBands.initialQuantity}
            placeholder={t('taxBands.table.initialQuantity')}
            error={error}
            type={'number'}
            numberOfColumnsInRow={3}
          />
          <DatePickerColumn
            label={t('taxBands.table.handingOverDate')}
            name={'handingOverDate'}
            value={taxBands.handingOverDate}
            onChange={(name, date) => onChange({ name, value: date })}
            error={error}
            numberOfColumnsInRow={3}
          />
        </div>
        {showAllFields ? (
          <>
            <small className={'text-muted'}>{t('taxBands.extraFields.info')}</small>
            <div className="form-row mt-2">
              <InputColumn
                label={t('taxBands.table.damaged')}
                onChange={(e) => onChange({ name: e.target.name, value: e.target.value })}
                name={'damaged'}
                value={taxBands.damaged}
                placeholder={t('taxBands.table.damaged')}
                error={error}
                type={'number'}
                numberOfColumnsInRow={4}
                optional
              />
              <DatePickerColumn
                label={t('taxBands.table.damagedDate')}
                name={'damagedDate'}
                value={taxBands.damagedDate}
                onChange={(name, date) => onChange({ name, value: date })}
                error={error}
                numberOfColumnsInRow={4}
                optional
              />
              <InputColumn
                label={t('taxBands.table.lost')}
                onChange={(e) => onChange({ name: e.target.name, value: e.target.value })}
                name={'lost'}
                value={taxBands.lost}
                placeholder={t('taxBands.table.lost')}
                error={error}
                type={'number'}
                numberOfColumnsInRow={4}
                optional
              />
              <DatePickerColumn
                label={t('taxBands.table.lostDate')}
                name={'lostDate'}
                value={taxBands.lostDate}
                onChange={(name, date) => onChange({ name, value: date })}
                error={error}
                numberOfColumnsInRow={4}
                optional
              />
            </div>
            <div className="form-row">
              <InputColumn
                label={t('taxBands.table.destroyed')}
                onChange={(e) => onChange({ name: e.target.name, value: e.target.value })}
                name={'destroyed'}
                value={taxBands.destroyed}
                placeholder={t('taxBands.table.destroyed')}
                error={error}
                type={'number'}
                numberOfColumnsInRow={4}
                optional
              />
              <DatePickerColumn
                label={t('taxBands.table.destroyedDate')}
                name={'destroyedDate'}
                value={taxBands.destroyedDate}
                onChange={(name, date) => onChange({ name, value: date })}
                error={error}
                numberOfColumnsInRow={4}
                optional
              />
              <InputColumn
                label={t('taxBands.table.returned')}
                onChange={(e) => onChange({ name: e.target.name, value: e.target.value })}
                name={'returned'}
                value={taxBands.returned}
                placeholder={t('taxBands.table.returned')}
                error={error}
                type={'number'}
                numberOfColumnsInRow={4}
                optional
              />
              <DatePickerColumn
                label={t('taxBands.table.returnedDate')}
                name={'returnedDate'}
                value={taxBands.returnedDate}
                onChange={(name, date) => onChange({ name, value: date })}
                error={error}
                numberOfColumnsInRow={4}
                optional
              />
            </div>
            <div className="form-row">
              <InputColumn
                label={t('taxBands.table.used')}
                onChange={(e) => onChange({ name: e.target.name, value: e.target.value })}
                name={'used'}
                value={taxBands.used}
                placeholder={t('taxBands.table.used')}
                error={error}
                type={'number'}
                numberOfColumnsInRow={4}
                optional
              />
              <DatePickerColumn
                label={t('taxBands.table.usedDate')}
                name={'usedDate'}
                value={taxBands.usedDate}
                onChange={(name, date) => onChange({ name, value: date })}
                error={error}
                numberOfColumnsInRow={4}
                optional
              />
            </div>
          </>
        ) : null}
        <button className="btn btn-primary" type="button" onClick={() => addTaxBands(taxBands)}>
          {t('taxBands.form.submit')}
        </button>
      </div>
    </div>
  );
};
